<template>
    <div class="app-container">
        <el-card class="box-card" id="courierContainer">
            <div slot="header" class="clearfix">
                <span>SmartBill</span>
            </div>

            <div class="separator"></div>
            <div class="bannerSmartBill" style="text-align: center">
                <img :src="bannerSmartbill" height="400px;" />
            </div>
            <div class="separator"></div>

            <el-form ref="form" :model="form" :rules="formRules" label-position="top">
                <el-row :gutter="20">
                    <el-col :span="24" :md="12">
                        <el-form-item :label="$t('account.smartbill.use-smartbill')" prop="status">
                            <el-radio-group v-model="formStatus" size="small">
                                <el-radio :label="1" border>Activ</el-radio>
                                <el-radio :label="0" border>Inactiv</el-radio>
                            </el-radio-group>
                        </el-form-item>

                        <el-form-item v-if="formStatus" :label="$t('account.smartbill.email')" prop="email">
                            <el-input v-model="formEmail"></el-input>
                        </el-form-item>

                        <el-form-item v-if="formStatus" :label="$t('account.smartbill.token')" prop="token">
                            <el-input v-model="formToken"></el-input>
                        </el-form-item>
                    </el-col>

                    <el-col :span="24" :md="12" v-if="formStatus">
                        <el-form-item :label="$t('account.smartbill.products-code')" prop="products_code">
                            <el-select class="fullWidth" v-model="formProductsCode" filterable>
                                <el-option
                                    v-for="(code, index) in products_codes"
                                    :value="code.value"
                                    :label="code.text"
                                    :key="index"
                                ></el-option>
                            </el-select>
                        </el-form-item>

                        <el-form-item :label="$t('account.smartbill.estimate-serie')" prop="estimate_serie">
                            <el-input v-model="formEstimateSerie"></el-input>
                        </el-form-item>

                        <el-form-item :label="$t('account.smartbill.invoice-serie')" prop="invoice_serie">
                            <el-input v-model="formInvoiceSerie"></el-input>
                        </el-form-item>

                        <el-row :gutter="10">
                            <el-col :span="24" :md="12">
                                <el-form-item :label="$t('account.smartbill.warehouse')">
                                    <el-input v-model="formWarehouse"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="24" :md="12">
                                <el-form-item :label="$t('account.smartbill.warehouse-unit')">
                                    <el-input v-model="formWarehouseUnit"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>

                        <el-form-item :label="$t('account.smartbill.shipping-model')">
                            <el-input v-model="formShippingModel"></el-input>
                        </el-form-item>

                        <el-form-item :label="$t('account.smartbill.shipping-service')" prop="shipping_service">
                            <el-radio-group v-model="formShippingService" size="mini">
                                <el-radio :label="1" border>{{ this.$t('account.smartbill.service-type-1') }}</el-radio>
                                <el-radio :label="0" border>{{ this.$t('account.smartbill.service-type-0') }}</el-radio>
                            </el-radio-group>
                        </el-form-item>

                        <el-form-item>
                            <el-checkbox
                                v-model="formIssuePayment"
                                :true-label="1"
                                :false-label="0"
                                :label="$t('account.smartbill.issue-payment')"
                            ></el-checkbox>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-row :gutter="20">
                    <el-col v-if="formStatus" :span="12">
                        <el-button @click="checkAccount" type="primary" class="fullWidth">{{
                            $t('account.smartbill.button-check')
                        }}</el-button>
                    </el-col>

                    <el-col :span="formStatus ? 12 : 24">
                        <el-button @click="submitForm" type="success" class="fullWidth">{{
                            $t('account.smartbill.button-save')
                        }}</el-button>
                    </el-col>
                </el-row>
            </el-form>
        </el-card>
    </div>
</template>

<script>
import { call, get, sync } from 'vuex-pathify';
import bannerSmartbill from '@/assets/images/smartbill.png';

export default {
    data() {
        return {
            bannerSmartbill,
            formRules: {
                status: [
                    {
                        required: true,
                        message: this.$t('account.smartbill.error-required'),
                        trigger: ['change', 'blur'],
                    },
                ],
                email: [
                    {
                        required: true,
                        message: this.$t('account.smartbill.error-required'),
                        trigger: ['change', 'blur'],
                    },
                ],
                token: [
                    {
                        required: true,
                        message: this.$t('account.smartbill.error-required'),
                        trigger: ['change', 'blur'],
                    },
                ],
                products_code: [
                    {
                        required: true,
                        message: this.$t('account.smartbill.error-required'),
                        trigger: ['change', 'blur'],
                    },
                ],
                estimate_serie: [
                    {
                        required: true,
                        message: this.$t('account.smartbill.error-required'),
                        trigger: ['change', 'blur'],
                    },
                ],
                invoice_serie: [
                    {
                        required: true,
                        message: this.$t('account.smartbill.error-required'),
                        trigger: ['change', 'blur'],
                    },
                ],
                shipping_service: [
                    {
                        required: true,
                        message: this.$t('account.smartbill.error-required'),
                        trigger: ['change', 'blur'],
                    },
                ],
            },
            products_codes: [
                {
                    text: this.$t('account.smartbill.products-model'),
                    value: 'products_model',
                },
                {
                    text: this.$t('account.smartbill.vendor-ext-id'),
                    value: 'vendor_ext_id',
                },
            ],
        };
    },
    components: {
        Box: () => import('vue-info-box-element'),
    },
    computed: {
        form: get('account/smartbill@form'),
        ...sync('account/smartbill@form', {
            formEmail: 'email',
            formToken: 'token',
            formProductsCode: 'products_code',
            formEstimateSerie: 'estimate_serie',
            formInvoiceSerie: 'invoice_serie',
            formWarehouse: 'warehouse',
            formWarehouseUnit: 'warehouse_unit',
            formShippingService: 'shipping_service',
            formShippingModel: 'shipping_model',
            formIssuePayment: 'issue_payment',
            formStatus: 'status',
        }),
    },
    methods: {
        getSmartBillStatus: call('account/GetSmartBillStatus'),
        submitSmartBillData: call('account/SubmitSmartBillData'),
        checkSmartBillAccount: call('account/CheckSmartBillAccount'),
        submitForm() {
            this.$refs.form.validate((valid) => {
                if (valid) {
                    this.submitSmartBillData()
                        .then((res) => {
                            if (res.message.success) {
                                this.sbMsg({
                                    type: 'success',
                                    message: this.$t('account.smartbill.saved'),
                                });
                            }
                        })
                        .catch((err) => {
                            this.$reportToSentry(err, {
                                extra: {
                                    fn: 'submitSmartBillData',
                                    params: this.form,
                                },
                            });
                        });
                } else {
                }
            });
        },
        checkAccount() {
            this.checkSmartBillAccount().then((res) => {
                if (res.message.verificat) {
                    this.sbMsg({
                        type: 'success',
                        message: this.$t('account.smartbill.verified'),
                    });
                }
            });
        },
    },
    created() {
        this.getSmartBillStatus().catch((err) => {
            this.$reportToSentry(err, {
                extra: {
                    fn: 'getSmartBillStatus',
                },
            });
        });
    },
};
</script>

<style lang="scss">
.el-form-item--small .el-form-item__label {
    line-height: 14px;
}

.el-form-item--small.el-form-item {
    margin-bottom: 22px;
}

.el-form-item {
    margin-bottom: 15px;
}

.el-form-item .el-form-item__label {
    line-height: 14px;
}
</style>
